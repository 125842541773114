<template>
  <div class="user-details">
    <div class="profile-header profile-bg">
      <div class="row">
        <div class="col-md-2 text-right hovereffect pl-5 pr-0 mr-0">
          <img style="height:100%;width:100%;" :src="profileImg" alt="User Avatar" :key="updateLoggedUserInfo"
               class="mr-3 avatar">
        </div>
        <div class="col-md-6">
          <div class="profiler-detail pl-4 pr-4">
            <h3>{{ userProfileData.campski.company || "N/A" }}</h3>
            <div class="d-flex">
              <div class="left d-flex">
                <h5 class="pt-1">
                  {{ userProfileData && userProfileData.membership ? userProfileData.membership.name : '' }}
                  Membership</h5>
              </div>
              <div class="right" style="margin-left:auto;align-items: center;">
                <h5 class="text-white text-right d-flex" style="align-items: center;"><span
                    class="lable text-white mr-2">Membership#  </span>{{ userProfileData.confirmation_code }} </h5>
              </div>
              <div
                  class="right"
                  style="margin-left: auto; align-items: center;"
              >
                <div class="autorenewal">

                </div>
              </div>
            </div>

            <div class="b-der"></div>
            <div class="d-flex">
              <div class="reg_date mt-3 mr-3">
                <div class="d-flex align-items-center">
                  <div class="up_icon mr-4">
                    <img src="/dist/img/profile/ico-registerd-date.png">
                  </div>
                  <div class="up_text">
                    <span class="lable">Registered Date</span>
                    <span> {{ startDate || "N/A" }}</span>
                  </div>
                </div>

              </div>
              <div class="ex_date mt-3 ml-3">
                <div class="d-flex align-items-center">
                  <div class="up_icon mr-4">
                    <img src="/dist/img/profile/ico-expired-date.png">
                  </div>
                  <div class="up_text">
                    <span class="lable">Expiration Date</span>
                    <span> {{ validThru || "N/A" }}<ChangeRegExpirationDate v-if="getSiteProps('general.change_expiry_date')" :memberType="'member'" :memId="currentMemberId" :updateExpireDate="validThru" @reload="displayAlertAndReloadData($event)"></ChangeRegExpirationDate></span>
                  </div>
                </div>

              </div>
              <div class="mt-4 ml-5">
                <div class="d-flex align-items-center">
                  <h5 class=" text-right d-flex"  style="align-items: center;" v-if="userNote.notes.length > 0">
                    <a href="#notes_section" class="text-white"><i class="text-success fa fa-check"></i> Notes</a><span class="lable text-white mr-2" v-b-modal.note-modal variant="light" v-on:click="addnote()">&nbsp; <i class="fas fa-plus-circle"></i></span>
                  </h5>
                  <h5 class="text-white text-right d-flex"  style="align-items: center;" v-else>
                    <span class="lable text-white mr-2" v-b-modal.note-modal variant="light" v-on:click="addnote()"><i class="fa fa-sticky-note"></i> Add Note</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 pl-0 pr-5"> <!-- Temp condition for shooting -->
          <div class="mcard">
            <membercard :member-id="userProfileData.member_id" :key="updateLoggedUserInfo"></membercard>
          </div>
          <div class="mt-3">
            <a href="javascript:void(0)" @click="refreshCard" class="refreshCardLink"><i class="fas fa-redo" :class="refreshProgress ? 'fa-spin' : ''"></i> Refresh Card</a>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-body mt-4">
      <custom-alert v-if="displayAlert==true" :message="alertMessage"
                    v-on:showedAlert="resetAlert"></custom-alert>
      <div class="row">
        <div class="col-md-6 r-pad">
          <div class="card mb-3">
            <div class="card-header container-fluid">
              <div class="row">
                <div class="col-md-7">
                    <div class="header-title">
                      <h3>{{userProfileData.membership.name}} Information</h3>
                    </div>
                  </div>
                <div class="col-md-5 float-right">
                  <button v-b-modal.modal-center1  @click="editresetvalue" class="btn btn-outline-primary btn-sm float-right ml-1"><span class="fa fa-edit"></span> Edit</button>
                  <button class="btn btn-outline-primary btn-sm float-right" @click="impersonate(userProfileData.confirmation_code)" ><span class="fa fa-user-secret"></span> Login as {{userProfileData.confirmation_code || "N/A"}}</button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Camp/School Name</label>
                    <span>{{ userProfileData.campski.company || "N/A" }}</span>

                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Contact Name (Director)</label>
                    <span>{{ userProfileData.full_name || "N/A" }}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Membership #</label>
                    <span>{{userProfileData.confirmation_code || "N/A"}}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <div class="d-flex">
                      <label class="form-label">Membership Status  <span v-if="userProfileData.membership_status_info" v-b-tooltip.hover :title="userProfileData.membership_status_info">
                                             <img  style="width:18px;" v-if="getSiteProps('profile.membershipStatusIcon')" v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
                                            </span></label>
                      <span class="text-danger bordernote" v-if="userProfileData.note" v-b-tooltip.hover :title="userProfileData.note"><i class="fas fa-clipboard"></i></span>
                    </div>

                    <span class="text-success" v-if="userProfileData.status_text == 'Current'"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ userProfileData.status_text }} (Expires {{userProfileData.valid_thru | formatDob }})</span>
                    <span class="text-warning" v-else-if="userProfileData.status_text != 'Expired'"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ userProfileData.status_text || 'N/A' }}</span>
                    <span class="text-warning" v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Expired</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Start Date</label>
                    <span>{{ startDate }}</span>
                  </div>
                </div>
                <div  class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Expiration Date</label>
                    <span>{{ userProfileData && userProfileData.membership && userProfileData.membership.period != 999 ? validThru : "N/A"}}</span>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="form-group">
                    <label class="form-label">Website</label>
                     <span>{{userProfileData.campski.website || "N/A"}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="col-md-6 l-pad">
        <div class="card">
          <div class="card-header">
            <div class="header-title">
              <h3>Additional Information</h3>
            </div>
            <div class="modal-btn">
              <b-button v-b-modal.modal-center4 @click="editresetvalue"><span class="fa fa-edit"></span> Edit</b-button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Closest Airport</label>
                  <span>{{ userProfileData.campski.closest_airport || "N/A" }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Number of Instructors</label>
                  <span>{{ userProfileData.campski.number_of_instructor }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Type of Camp/School</label>
                  <span>{{ userProfileData.campski.type || "N/A" }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Months of Operation</label>
                  <span>{{ userProfileData.campski.month_of_operation }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Disciplines Taught</label>
                  <span><ul><li v-for="(discipline) in discipline_taught_arr" v-bind:key="'discipline'+discipline">{{ discipline || "N/A"}}</li></ul></span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Equipment</label>
                  <span><ul><li v-for="(equipment) in equipment_arr" v-bind:key="'equipment'+equipment">{{ equipment || "N/A" }}</li></ul></span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Skill Levels Taught</label>
                  <span>{{ userProfileData.campski.skill_level_taught || "N/A" }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Boats</label>
                  <span>{{ userProfileData.campski.boats || "N/A" }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Waterway Type</label>
                  <span>{{ waterWayData }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-label">Waterfront Site Description</label>
                  <span>{{ waterFrontData }}</span>
                </div>
              </div>
              <div class="col-md-6 col-lg-8">
                <div class="form-group">
                  <label class="form-label">Additonal Information</label>
                  <span>{{ userProfileData.campski.additional_information }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="profile-body mt-1 mb-2">
      <div class="row">
        <div class="col-md-12 l-pad">
          <div class="card">
            <div class="card-header">
              <div class="header-title">
                <h3>Contact Information</h3>
              </div>
              <div class="modal-btn">
                <b-button v-b-modal.modal-center2 @click="editresetvalue"><span class="fa fa-edit"></span> Edit</b-button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Email</label>
                        <span><a class="email-link" v-bind:href="'mailto:' + userProfileData.email ">{{ userProfileData.email || "N/A" }}</a></span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Phone Number</label>
                        <span>{{ userProfileData.phone_1 || "N/A" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Camp/School Site Address (Line 1)</label>
                        <span>{{ primaryContact.address1 || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Address (Line 2)</label>
                        <span>{{ primaryContact.address2 || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">City</label>
                        <span>{{ primaryContact.city || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">State</label>
                        <span>{{ primaryContact.state_code || primaryContact.state || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Zip Code</label>
                        <span>{{ primaryContact.zip || "" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Mailing Address (if different)</label>
                        <span>{{ secondaryContact.address1 || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Address (Line 2) </label>
                        <span>{{ secondaryContact.address2 || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">City</label>
                        <span>{{ secondaryContact.city || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">State</label>
                        <span>{{ secondaryContact.state_code || secondaryContact.state || "" }}</span>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                      <div class="form-group">
                        <label class="form-label">Zip Code</label>
                        <span>{{ secondaryContact.zip || "" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row profile-body " >
      <div class="col-md-12">
        <div class="card table-head Merchandise">
          <div class="card-header">
            <div class="header-title">
              <h3>Membership History</h3>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body pt-0 table-responsive">
            <b-table
                id="my-table"
                :items="userProfileData.membership_history"
                :fields="tblFields"
                class="mt-3"
                hover
                show-empty
            >
              <template #empty="scope">
                <h4>{{ scope.emptyText }}</h4>
              </template>
              <template #cell(status)="row">
                {{row.item.transaction_status}}
              </template>
              <template #cell(transaction_id)="row">
                {{ row.value === "" ? "N/A" : row.value }}
              </template>
              <template #cell(receipt)="row">
                <div class="text-left py-0 align-middle" v-if="getSiteProps('general.receipt_eligible_registration_status').includes(row.item.status)">
                  <div class="btn-group btn-group-sm">
                    <button @click="downloadDocs(row.item.id, 'payment')"
                            class="download-btn ml-1 mt-0"><img src="/dist/img/profile/ico-download.png"></button>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <!-- /.card-body -->
        </div>


      </div>
    </div>
    <div class="row profile-body" id="noteList">
      <div class="col-md-12">
        <div class="card table-head Merchandise mb-4">
          <div class="card-header">
            <div class="header-title" style="width: 100%">
              <div class="row">
                <div class="col-md-11">
                  <h3>Notes</h3>
                </div>
                <div class="col-md-1 text-right pt-1">
                  <button class="btn btn-primary btn-blue btn-sm" v-b-modal.note-modal @click="addnote()"><i class="fa fa-plus"></i> Add</button>
                </div>
              </div>
            </div>
          </div>
          <section id="notes_section">
            <div class="card-body pt-0 table-responsive">
              <b-table
                  id="myMerchandiseTable"
                  :items="userNote.notes"
                  :fields="notesTbl"
                  class="mt-3"
                  hover
                  show-empty
              >
                <template #cell(note)="row">
                  {{row.item.note}}
                </template>
                <template #cell(name)="row">
                  {{row.item.user.first_name}}
                </template>
                <template #cell(status)="row">
                  {{row.item.status!='0' ? 'Active' : 'In Active'}}
                </template>
                <template #cell(actions)="row">
                  <div class="text-left py-0 align-middle">
                    <div class="btn-group btn-group-sm">
                      <button
                          data-bs-toggle="modal"  v-b-modal.note-modal
                          class="btn btn-primary ml-1"
                          @click="editNote(row.item, $event.target)"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </button>
                      <button
                          class="btn btn-danger ml-1"
                          @click="deleteNote(row.item.id)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </section>
        </div>
      </div>
    </div>
    <b-modal id="modalMembership" size="lg" ok-title="Update" @ok="updateMemberDetails" centered title="Membership Information">
      <form>
        <div class="row" v-if="userProfileData.membership.member_type_id!=3 || userProfileData.personal_details.relation == 0">

        </div>
      </form>
    </b-modal>
    <b-modal id="note-modal" hide-footer  :no-close-on-backdrop="true">
      <template #modal-title>
        <h3 v-if="!edit_note_id">Add Note</h3>
        <h3  v-else>Edit Note</h3>
      </template>

      <div class="form-group">
        <label for="note">Note:</label>
        <textarea type="text" id="note" v-model="note" size="lg" placeholder="Enter Note" class="form-control" ></textarea>
        <p class="text-danger note-error"></p>

      </div>

      <div class="form-group">
        <label for="notetype">Note Type:  </label>
        <select name="notetypeSelect" id="notetype" v-model="selectedNote"  class="form-control">
          <option disabled value=""> Select </option>
          <option v-for="(Notetypes,i) in Notetype.options" :key="i" :value=i>{{ Notetypes }}
          </option>

        </select>
        <p class="text-danger note-type-error"></p>
      </div>
      <!-- <div class="form-group">

          <b-form-checkbox  v-model="notestatus" name="notestatus" switch value="1">
           Status
          </b-form-checkbox>

      </div> -->
      <div>
        <button v-if="!edit_note_id"  type="button" class="btn btn-sm btn-primary" @click="storenote()" :disabled="noteProgress"><i class="fa mr-2" :class="noteProgress ? 'fa-circle-notch' : 'fa-save'"></i> Submit</button>
        <button v-else  type="button" class="btn btn-sm btn-primary" @click="updatenote()" :disabled="noteProgress"><i class="fa mr-2" :class="noteProgress ? 'fa-circle-notch' : 'fa-save'"></i> Update</button>
        &nbsp;
        <!-- <button  type="button" class="btn btn-sm btn-secondary"  @click="$bvModal.hide('note-modal')">Close</button> -->
      </div>
    </b-modal>
    <b-modal id="modal-center1" size="lg" ok-title="Update" @ok="updateSchoolInfo" @cancel="editresetvalue" centered
             :title="userProfileData.membership.name+' Information'">
      <div class="form-body">
        <form data-vv-scope="form-1">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Contact Name (Director)<sup class="text-danger">*</sup></label>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="First *" data-vv-as="first name" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-1.first_name') }" class="form-control"
                           v-model="form1.first_name"
                           name="first_name">
                    <div v-show="formErrors.has('form-1.first_name')" class="invalid-feedback">
                      {{ formErrors.first('form-1.first_name') }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Last *" data-vv-as="last name" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-1.last_name') }" class="form-control"
                           v-model="form1.last_name"
                           name="last_name">
                    <div v-show="formErrors.has('form-1.last_name')" class="invalid-feedback">
                      {{ formErrors.first('form-1.last_name') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Camp/School Name<sup class="text-danger">*</sup></label>
                <input type="text" v-model="form1.company" data-vv-as="camp/school name" v-validate="'required'"
                       :class="{ 'is-invalid': formErrors.has('form-1.company') }" class="form-control"
                       id="company" name="company"/>
                <div v-show="formErrors.has('form-1.company')" class="invalid-feedback">
                  {{ formErrors.first('form-1.company') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Website<sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" v-validate="'required|url'"
                       :class="{ 'is-invalid': formErrors.has('form-1.website') }" v-model="form1.website"
                       name="website">
                <div v-show="formErrors.has('form-1.website')" class="invalid-feedback">
                  {{ formErrors.first("form-1.website") }}
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="getSiteProps('membershipInfoEdit.startDate')">
              <div class="form-group">
                <label class="form-label">Start Date</label>
                <date-pick
                    v-model="form1.start_date"
                    :pickTime="false"
                    name="UpdateProfile.start_date"
                    id="startDate"
                    :format="'MM/DD/YYYY'"
                    :selectableYearRange="{from: 1900, to: 2021}"
                    v-mask="'##/##/####'"
                    placeholder="MM/DD/YYYY"
                    class="form-control"
                    :isDateDisabled="isStartDisabledDate"
                    :parseDate="parseDatePick"
                ></date-pick>
                <span class="text-sm text-danger">{{
                    errors.start_date
                  }}</span>
              </div>
            </div>
            <div  class="col-md-6" v-if="getSiteProps('membershipInfoEdit.expireDate')">
              <div class="form-group">
                <label class="form-label">Expiration Date</label>
                <date-pick
                    v-model="form1.end_date"
                    :pickTime="false"
                    name="UpdateProfile.valid_thru"
                    id="validThru"
                    :format="'MM/DD/YYYY'"
                    :selectableYearRange="{from: 2022, to: 2025}"
                    v-mask="'##/##/####'"
                    placeholder="MM/DD/YYYY"
                    class="form-control"
                    :isDateDisabled="isEndDisabledDate"
                    :parseDate="parseDatePick"
                ></date-pick>
                <span class="text-sm text-danger">{{
                    errors.end_date
                  }}</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="modal-center2" size="lg" ok-title="Update" @ok="updateAddressInfo" @cancel="editresetvalue" centered
             :title="'Contact Information'">
      <div class="form-body">
        <form data-vv-scope="form-2">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Email<sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" data-vv-as="email" v-validate="'required|email'"
                       :class="{ 'is-invalid': formErrors.has('form-2.email') }" v-model="form.email"
                       name="email">
                <div v-show="formErrors.has('form-2.email')" class="invalid-feedback">
                  {{ formErrors.first('form-2.email') }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Phone Number<sup class="text-danger">*</sup></label>
                <vue-tel-input v-model="form.phone_1" ref="phone" v-bind="phoneConfig" @validate="validateTelinput" :validCharactersOnly="true"></vue-tel-input>
                <div class="invalid-feedback" v-show="phone_1_error">{{ phone_1_error }}</div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-label">Camp/School Site Address <sup class="text-danger">*</sup></label>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 1" data-vv-as="Address" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.address1') }" class="form-control"
                           v-model="form.primaryContact.address1"
                           name="address1">
                    <div v-show="formErrors.has('form-2.address1')" class="invalid-feedback">
                      {{ formErrors.first("form-2.address1") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 2" data-vv-as="line 2" class="form-control"
                           v-model="form.primaryContact.address2"
                           name="address1">
                    <!-- <div v-show="formErrors.has('form-2.address2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.address2") }}
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 d-none">
                  <div class="form-group">
                    <select v-validate="'required'"
                            :class="{ 'is-invalid': formErrors.has('form-2.country') }" class="form-control"
                            name="country"
                            v-model="form.primaryContact.country">
                      <option value="">Select Country</option>
                      <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries"
                              :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">
                        {{ listOfCountry.country }}
                      </option>
                    </select>
                    <div v-show="formErrors.has('form-2.country')" class="invalid-feedback">
                      {{ formErrors.first("form-2.country") }}
                    </div>
                  </div>

                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="City" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.city') }" class="form-control"
                           v-model="form.primaryContact.city"
                           name="city">
                    <div v-show="formErrors.has('form-2.city')" class="invalid-feedback">
                      {{ formErrors.first("form-2.city") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div v-if="form.primaryContact.country =='USA'">
                    <div class="form-group">
                      <select v-validate="'required'" name="state_code"
                              :class="{ 'is-invalid': formErrors.has('form-2.state_code') }" class="form-control"
                              v-model="form.primaryContact.state_code">
                        <option value="">Select State</option>
                        <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                :value="listOfState.state_code">{{ listOfState.state }}
                        </option>
                      </select>
                      
                      <div v-show="formErrors.has('form-2.state_code')" class="invalid-feedback">
                        {{ formErrors.first("form-2.state_code") | filterStateCode }}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="form-group">
                      <input type="text" placeholder="State" v-validate="'required'"
                             :class="{ 'is-invalid': formErrors.has('form-2.state') }" class="form-control"
                             v-model="form.primaryContact.state"
                             name="state">
                      <div v-show="formErrors.has('form-2.state')" class="invalid-feedback">
                        {{ formErrors.first("form-2.state") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="Zip Code"
                           v-validate="{ required: true, regex: /^([0-9]+)$/ }"
                           :class="{ 'is-invalid': formErrors.has('form-2.zip') }" class="form-control"
                           v-model="form.primaryContact.zip"
                           maxlength="5"
                           name="zip">
                    <div v-show="formErrors.has('form-2.zip')" class="invalid-feedback">
                      {{ formErrors.first("form-2.zip") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <label class="form-label">Mailing Address <sup class="text-danger"> * </sup> <input  name="same" style="margin-left: 30px" type="checkbox" v-on:change="isSame()" id='same' v-model="form.same" value=true> Same as Site address</label>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 1"
                            class="form-control"
                           data-vv-as="Mailing Address" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.mailingAddress') }"
                           v-model="form.secondaryContact.address1"
                           name="mailingAddress">
                    <div v-show="formErrors.has('form-2.mailingAddress')" class="invalid-feedback">
                      {{ formErrors.first("form-2.mailingAddress") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <input type="text" placeholder="Line 2" class="form-control"
                           v-model="form.secondaryContact.address2"
                           name="address1">
                    <!-- <div v-show="formErrors.has('form-2.address2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.address2") }}
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 d-none">
                  <div class="form-group">
                    <select class="form-control" name="country" v-model="form.secondaryContact.country">
                      <option value="">Select Country</option>
                      <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries"
                              :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">
                        {{ listOfCountry.country }}
                      </option>
                    </select>
                  </div>

                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="City"
                           class="form-control"
                           v-model="form.secondaryContact.city"
                           data-vv-as="City" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.city2') }"
                           name="city2">
                    <div v-show="formErrors.has('form-2.city2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.city2") }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <select name="state_code2" class="form-control"
                              data-vv-as="State" v-validate="'required'"
                              :class="{ 'is-invalid': formErrors.has('form-2.state_code2') }"
                              v-model="form.secondaryContact.state_code">
                        <option value="">Select State</option>
                        <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                :value="listOfState.state_code">{{ listOfState.state }}
                        </option>
                      </select>
                      <div v-show="formErrors.has('form-2.state_code2')" class="invalid-feedback">
                        {{ formErrors.first("form-2.state_code2") }}
                      </div>
                    </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input type="text" placeholder="Zip Code"
                           class="form-control"
                           data-vv-as="Zip" v-validate="'required'"
                           :class="{ 'is-invalid': formErrors.has('form-2.zip2') }"
                           v-model="form.secondaryContact.zip"
                           maxlength="5"
                           name="zip2">
                    <div v-show="formErrors.has('form-2.zip2')" class="invalid-feedback">
                      {{ formErrors.first("form-2.zip2") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="modal-center4" size="lg" ok-title="Update" @ok="updateInfo" @cancel="editresetvalue" centered
             :title="'Additional Information'">
      <div class="form-body">
        <form data-vv-scope="form-4">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Closest Airport</label>
                <input type="text" class="form-control" v-model="form4.closest_airport"
                       name="closest_airport">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group tooltipcss">
                <label class="form-label">Type of Camp/School</label>
                <b-button v-b-tooltip.hover
                          title="Please describe the type of camp or school (e.g. boys, girls, overnight, etc.)."><img
                    v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon"/></b-button>
                <input type="text" class="form-control" v-model="form4.type" name="type">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Number of Instructors</label>
                <input type="number" class="form-control" v-validate="{ regex: /^([0-9]+)$/ }"
                       v-model="form4.number_of_instructor"
                       name="number_of_instructor">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Months of Operation <sup class="text-danger">*</sup></label>
                <input type="text" data-vv-as="months of operation" class="form-control"
                       v-validate="'required'"
                       :class="{ 'is-invalid': formErrors.has('form-4.month_of_operation') }"
                       v-model="form4.month_of_operation"
                       name="month_of_operation">
                <div v-show="formErrors.has('form-4.month_of_operation')" class="invalid-feedback">
                  {{ formErrors.first("form-4.month_of_operation") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Disciplines Taught<sup class="text-danger">*</sup></label>

              </div>
              <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-4.equipment') }">
                <div v-for="(dt, index) in disciplineTaught" :key="index">
                  <input v-validate="'required'" name="discipline_taught" data-vv-as="discipline taught" type="checkbox" :id="'dtaught'+index" v-model="form4.discipline_taught" :value="dt.value">
                  <label :for="'dtaught'+index"> {{ dt.text }}</label>
                </div>
                <div >
                </div>


              </div>
              <div v-show="formErrors.has('form-4.discipline_taught')" class="invalid-feedback">
                {{ formErrors.first("form-4.discipline_taught") }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Equipment<sup class="text-danger">*</sup></label>
                <div class="form-group form-check"
                     :class="{ 'is-invalid': formErrors.has('form-4.equipment') }">
                  <div v-for="(eq, index) in equipment" :key="index">
                    <input v-validate="'required'" name="equipment" type="checkbox" :id="'equipment'+index" v-model="form4.equipment" :value="eq.value">
                    <label :for="'equipment'+index"> {{ eq.text }}</label>
                  </div>
                </div>
                <div v-show="formErrors.has('form-4.equipment')" class="invalid-feedback">
                  {{ formErrors.first("form-4.equipment") }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Skill Levels Taught<sup class="text-danger">*</sup></label>
                <div class="form-group form-check"
                     :class="{ 'is-invalid': formErrors.has('form-4.skill_level_taught') }">
                  <div v-for="(st, index) in skillTaught" :key="index">
                    <input v-validate="'required'" name="skill_level_taught" data-vv-as="skill levels taught" type="checkbox" :id="'skilllevel'+index" v-model="form4.skill_level_taught" :value="st.value">
                    <label :for="'skilllevel'+index"> {{ st.text }}</label>
                  </div>
                </div>
                <div v-show="formErrors.has('form-4.skill_level_taught')" class="invalid-feedback">
                  {{ formErrors.first("form-4.skill_level_taught") }}
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label">Boats<sup class="text-danger">*</sup></label>
              <div class="form-group form-check" :class="{ 'is-invalid': formErrors.has('form-4.boats') }">

                <div v-for="(bt, index) in boats" :key="index">
                  <input v-validate="'required'" name="boats"  type="checkbox" :id="'boats'+index" v-model="form4.boats" :value="bt.value">
                  <label :for="'boats'+index"> {{ bt.text }}</label>
                </div>

              </div>
              <div v-show="formErrors.has('form-4.boats')" class="invalid-feedback">
                {{ formErrors.first("form-4.boats") }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="">
                <label for="clubandteam" class="form-label">Waterway Type <sup
                    class="text-danger">*</sup></label>
              </div>
              <div class="form-group">

                <select class="form-control" data-vv-as="waterway Type" v-validate="'required'"
                        :class="{ 'is-invalid': formErrors.has('form-4.waterway_type') }"
                        v-model="form4.waterway_type" name="waterway_type">
                  <option value="">Select</option>
                  <option value="1">Natural Freshwater Lake</option>
                  <option value="2">Man-made Freshwater Lake</option>
                  <option value="3">River</option>
                  <option value="4">Reservoir</option>
                  <option value="5">Intracoastal</option>
                  <option value="6">Saltwater</option>
                </select>
                <div v-show="formErrors.has('form-4.waterway_type')" class="invalid-feedback">
                  {{ formErrors.first("form-4.waterway_type") }}
                </div>

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-label">Waterfront Site Description<sup class="text-danger">*</sup></label>
                <select class="form-control" data-vv-as="water front" v-validate="'required'"
                        :class="{ 'is-invalid': formErrors.has('form-4.water_front') }"
                        v-model="form4.water_front" name="water_front">
                  <option value="">Select</option>
                  <option value="1">Private</option>
                  <option value="2">Semi Private</option>
                  <option value="3">Public</option>
                </select>
                <div v-show="formErrors.has('form-4.water_front')" class="invalid-feedback">
                  {{ formErrors.first("form-4.water_front") }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Addittional Information</label>
                <textarea type="text" class="form-control" v-model="form4.additional_information"
                          name="additional_information"></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>


</template>
<script>
import axios from 'axios';
import moment from "moment";
import CustomAlert from "../CustomAlert";
import ChangeRegExpirationDate from "./changeRegExpirationDate";
import membercard from "../Member/Dashboard/profile/membercard";
import DatePick from "vue-date-pick";
import * as $ from "jquery";
import { VueTelInput } from 'vue-tel-input';

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "Phone Number");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter valid phone number.' };
  }
  return { valid: true, error: "" };
}

export default {

  name: 'campskiprofile',
  components: {ChangeRegExpirationDate,CustomAlert,membercard, VueTelInput },
  data() {
    return {
      isFirsttime:true,
      userProfileData: '',
      UpdateProfile: {
        membership_id: '',
        membership_status: '',
        start_date: "",
        valid_thru: ""
      },
      phone_1_error: "",
      validTelinput:false,
      phoneConfig: {
        mode: "international",
        defaultCountry: "US",
        styleClasses: this.phone_1_error !== "" ? "is-invalid" : "",
        inputOptions: {
          autocomplete: "on",
          autofocus: false,
          id: "phone_1",
          maxlength: 15,
          name: "phone_number",
          placeholder: "",
          readonly: false,
          required: false,
          showDialCode: false,
          tabindex: 0,
          type: "tel"
        }
      },
      discipline_taught_arr:[],
      equipment_arr:[],
      primaryContact:[],
      secondaryContact:[],
      updateMemberTypeData:[],
      currentMemberId:0,
      profileImg: '/dist/img/profile1.png',
      waterWayData: 'N/A',
      waterFrontData: 'N/A',
      selectedNote:'',
      updateLoggedUserInfo:0,
      userNote:'',
      edit_note_id:'',
      Notetype: {},
      notestatus:1,
      refreshProgress: false,
      autoRenewData: {
        regId: "",
        status: "",
        startDate: "",
        validThru: ""
      },
      tblFields: [
        {key: 'membership.name', sortable: true, label: 'Registration Type', formatter: 'formatName'},
        {key: 'transaction_id', sortable: true, label: 'Transaction Id', formatter: 'formatName'},
        {key: 'transaction_date', sortable: true, label: 'Transaction Date', formatter: 'formatDate'},
        {key: 'status', sortable: true, label: 'Status', formatter: 'formatName'},
        {key: 'start_date', sortable: true, label: 'Effective From', formatter: 'formatDate'},
        {key: 'valid_thru', sortable: true, label: 'Effective To', formatter: 'formatDate'},
        {key: 'receipt', sortable: false, label: 'Receipt'},
      ],
      notesTbl:[
        {key: 'note', sortable: true, label: 'Notes', thStyle: { width: '400px'}},
        {key: 'note_type', sortable: true, label: 'Note Type', formatter: 'getNoteTypeName'},
        {key: 'status', sortable: true, label: 'Status', thClass: 'd-none', tdClass: 'd-none' },
        {key: 'name', sortable: true, label: 'Created By', formatter: 'formatName'},
        {key: 'created_at', sortable: true, label: 'Date', formatter: 'formatDatenew'},
        {key: "actions", sortable: false }
      ],
      disciplineTaught: [
        {text: 'Slalom', value: 'Slalom'},
        {text: 'Trick', value: 'Trick'},
        {text: 'Wakeboard', value: 'Wakeboard'},
        {text: 'Barefoot', value: 'Barefoot'},
        {text: 'Hydrofoil', value: 'Hydrofoil'},
        {text: 'Jump', value: 'Jump'},
        {text: 'Wake Surf', value: 'Wake Surf'},
        {text: 'Show Ski', value: 'Show Ski'},
        {text: 'Adaptive', value: 'Adaptive'},
        {text: 'Kneeboard', value: 'Kneeboard'},
      ],
      equipment: [
        {text: 'Slalom Skis', value: 'Slalom Skis'},
        {text: 'Trick Skis', value: 'Trick Skis'},
        {text: 'Jump Skis', value: 'Jump Skis'},
        {text: 'Beginner Combo Skis', value: 'Beginner Combo Skis'},
        {text: 'Sit Skis (Adaptive)', value: 'Sit Skis (Adaptive)'},
        {text: 'Show Swivel Skis', value: 'Show Swivel Skis'},
        {text: 'Shoe Skis', value: 'Shoe Skis'},
        {text: 'Wake Surf', value: 'Wake Surf'},
        {text: 'Kneeboards', value: 'Kneeboards'},
        {text: 'Wakeboards', value: 'Wakeboards'},
        {text: 'Hydrofoils', value: 'Hydrofoils'},
        {text: 'Students Bring Their Own Equipment', value: 'Students Bring Their Own Equipment'},
      ],
      skillTaught: [
        {text: 'Beginning', value: 'Beginning'},
        {text: 'Intermediate', value: 'Intermediate'},
        {text: 'Advanced', value: 'Advanced'}
      ],
      boats: [
        {text: 'Competition Inboard', value: 'Competition Inboard'},
        {text: 'Competition Outboard', value: 'Competition Outboard'},
        {text: 'Recreational Inboard/Outboard', value: 'Recreational Inboard/Outboard'},
        {text: 'Jet Boat', value: 'Jet Boat'}
      ],
      waterWay: {
        '1': 'Natural Freshwater Lake',
        '2': 'Man-made Freshwater Lake',
        '3': 'River',
        '4': 'Reservoir',
        '5': 'Intracoastal',
        '6': 'Saltwater'
      },
      waterFront: {
        '1': 'Private',
        '2': 'Semi Private',
        '3': 'Public',
      },
      campArrayData:[
          'equipment',
          'boats',
          'skill_level_taught',
          'discipline_taught'
      ],
      form1:{
        'form_type':'school',
        'company': '',
        'first_name': '',
        'last_name': '',
        'website':'',
        'start_date':'',
        'end_date':'',
      },
      form: {
        'form_type':'address',
        "email": '',
        "same":false,
        "primaryContact": {
            "address1": '',
            "address2": '',
            "zip": '',
            "state_code": '',
            "state": '',
            "city": '',
            "country": 'USA',
          },
        "secondaryContact": {
          "address1": '',
          "address2": '',
          "zip": '',
          "state_code": '',
          "state": '',
          "city": '',
          "country": 'USA',
        },
        "phone_1": '',
        "phone_2": '',
      },
      form4:{
        'form_type':'information',
        'closest_airport': '',
        'number_of_instructor': '',
        'type': '',
        'month_of_operation': '',
        'discipline_taught': [],
        'equipment': [],
        'skill_level_taught': [],
        'boats': [],
        'waterway_type': '',
        'water_front': '',
        'additional_information': ''
      }
    }
  },
  methods: {
    validateTelinput(obj){
      this.validTelinput = obj.valid;
      if(obj.valid){
        this.phone_1_error = '';
      }
    },
    isSame(){
      if(this.form.same){
        this.form.secondaryContact = Object.assign({},this.form.primaryContact);
        this.form.secondaryContact.primary = 0;
      }
      else{
        this.form.secondaryContact.address1 = "";
        this.form.secondaryContact.address2 = "";
        this.form.secondaryContact.zip = "";
        this.form.secondaryContact.state_code = "";
        this.form.secondaryContact.state = "";
        this.form.secondaryContact.city = "";
        this.form.secondaryContact.country = "USA";
      }
    },
    refreshCard(event){
      this.isFirsttime = false;
      this.refreshProgress = true;
      let member_id = this.userProfileData.id;
      this.displayAlert = false;
      axios.post(this.basePath+"api/member/refresh-card", { member: member_id })
          .then(res => {
            this.alertMessage = "Member Card updated successfully";
            this.displayAlert = true;
            this.refreshProgress = false;
            this.updateLoggedUserInfo += 1;
          })
          .catch(err => {
            console.log(err);
            this.refreshProgress = false;
          })
    },
    userProfileApi() {
      this.regId = this.$route.params.regId;
      console.log(this.regId);
      axios.get(this.basePath + 'api/admin/get_member/' + this.regId, {headers: this.adminHeaders})
          .then((res) => {
            this.userProfileData = res.data.data;
            if(this.isFirsttime){
              this.refreshCard();
            }
            this.discipline_taught_arr = this.userProfileData.campski.discipline_taught.split(",");
            this.equipment_arr = this.userProfileData.campski.equipment.split(",");
            for(let i=0; i<this.userProfileData.campski_contact_details.length; i++)
            {
              if(this.userProfileData.campski_contact_details[i].primary == 1)
              {
                this.primaryContact = Object.assign({}, this.userProfileData.campski_contact_details[i]);
                this.form.primaryContact = Object.assign({},this.userProfileData.campski_contact_details[i]);
              }
              else
              {
                this.secondaryContact = Object.assign({}, this.userProfileData.campski_contact_details[i]);
                this.form.secondaryContact = Object.assign({}, this.userProfileData.campski_contact_details[i]);
              }
            }
            this.currentMemberId = this.userProfileData.id;
            this.startDate = moment(this.userProfileData.membership_startdate).format('MM/DD/YYYY');
            this.validThru = moment(this.userProfileData.membership_endtate).format('MM/DD/YYYY');
            this.UpdateProfile.start_date = moment(this.userProfileData.membership_startdate).format('MM/DD/YYYY');
            this.UpdateProfile.valid_thru = moment(this.userProfileData.membership_endtate).format('MM/DD/YYYY');
            this.form1.company = this.userProfileData.campski.company;
            this.form1.first_name = this.userProfileData.personal.first_name;
            this.form1.last_name = this.userProfileData.personal.last_name;
            this.form1.website = this.userProfileData.personal.website;
            this.form1.start_date = moment(this.userProfileData.membership_startdate).format('MM/DD/YYYY');
            this.form1.end_date = moment(this.userProfileData.membership_endtate).format('MM/DD/YYYY');
            this.UpdateProfile.membership_status = this.userProfileData.membership_status;
            this.UpdateProfile.membership_id = this.userProfileData.membership.id;
            let mTypes = this.getSiteProps('member_type_options');
            if(typeof mTypes !== 'undefined') {
              this.updateMemberTypeData = mTypes;
            }
            if (this.userProfileData.campski.waterway_type) {
              let ww = this.userProfileData.campski.waterway_type;
              this.waterWayData = this.waterWay[ww]
            }
            if (this.userProfileData.campski.water_front) {
              let wf = this.userProfileData.campski.water_front;
              this.waterFrontData = this.waterFront[wf]
            }
            for (let prop in this.userProfileData.personal) {
              if ( Object.prototype.hasOwnProperty.call(this.form, prop) ) {
                this.form[prop] = this.userProfileData.personal[prop]
              }
            }
            for (let prf in this.userProfileData) {
              if ( Object.prototype.hasOwnProperty.call(this.form, prf) ) {
                this.form[prf] = this.userProfileData[prf]
              }
            }
            for (let cs in this.userProfileData.campski) {
              if ( Object.prototype.hasOwnProperty.call(this.form4, cs) ) {
                let check = this.campArrayData.includes(cs);
                if(check){
                  this.form4[cs] = this.userProfileData.campski[cs].split(',')
                }else{
                  this.form4[cs] = this.userProfileData.campski[cs]
                }

              }
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            return false;
          });
    },
    editresetvalue(){
      for(let i=0; i<this.userProfileData.campski_contact_details.length; i++)
      {
        if(this.userProfileData.campski_contact_details[i].primary == 1)
        {
          this.form.primaryContact = Object.assign({},this.userProfileData.campski_contact_details[i]);
          if(this.form.primaryContact.state_code==null) {
            this.form.primaryContact.state_code = "";
          }
        }
        else
        {
          this.form.secondaryContact = Object.assign({}, this.userProfileData.campski_contact_details[i]);
          if(this.form.secondaryContact.state_code==null) {
            this.form.secondaryContact.state_code = "";
          }
        }
      }
      this.form1.company = this.userProfileData.campski.company;
      this.form1.first_name = this.userProfileData.personal.first_name;
      this.form1.last_name = this.userProfileData.personal.last_name;
      this.form1.website = this.userProfileData.personal.website;
      this.form1.start_date = moment(this.userProfileData.membership_startdate).format('MM/DD/YYYY');
      this.form1.end_date = moment(this.userProfileData.membership_endtate).format('MM/DD/YYYY');
      for (let prop in this.userProfileData.personal) {
        if ( Object.prototype.hasOwnProperty.call(this.form, prop) ) {
          this.form[prop] = this.userProfileData.personal[prop]
        }
      }
      for (let prf in this.userProfileData) {
        if ( Object.prototype.hasOwnProperty.call(this.form, prf) ) {
          this.form[prf] = this.userProfileData[prf]
        }
      }
      for (let cs in this.userProfileData.campski) {
        if ( Object.prototype.hasOwnProperty.call(this.form4, cs) ) {
          let check = this.campArrayData.includes(cs);
          if(check){
            this.form4[cs] = this.userProfileData.campski[cs].split(',')
          }else{
            this.form4[cs] = this.userProfileData.campski[cs]
          }

        }
      }
    },
    formatDate(value) {
      var date = moment(value, "YYYY-MM-DD hh:mm:ss");
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    isStartDisabledDate(date) {
      const currentDate = new Date(this.UpdateProfile.valid_thru);
      return date > currentDate;
    },
    isEndDisabledDate(date) {
      const currentDate = new Date(this.UpdateProfile.start_date);
      return date < currentDate;
    },
    downloadDocs(item, type) {
      this.downloadPdf(this.basePath + 'api/registration/download/'+type+'/'+item, 'Reg'+item+"_"+type );
    },
    downloadPdf(url, name){
      axios.get(url, {headers:this.adminHeaders, responseType: 'arraybuffer'})
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.userProfileData.member_id+"_"+name+'.pdf';
            link.click();
            link.remove();
          }).catch((error)=>{
        console.log(error);
      });
    },
    impersonate(code = 0)
    {
      axios
          .post(process.env.VUE_APP_URL + "api/impersonate/member", {
            code: code
          })
          .then((response) => {
            if (response.data.status == "success") {
              localStorage.removeItem('admintoken');
              localStorage.removeItem('admin');
              localStorage.setItem("membertoken", response.data.data.token);
              localStorage.setItem(
                  "member",
                  JSON.stringify(response.data.data.member)
              );
              localStorage.setItem("time", moment().unix());

              let d = new Date();
              d.setTime(d.getTime() + 60 * 60 * 1000);
              let expires = "expires=" + d.toUTCString();
              let hostName=window.location.hostname;
              let mainDomain=hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
              document.cookie = "LMSACCESSTOKEN=" + response.data.data.member.lmsAccessToken + ";domain=."+mainDomain+";" + expires + ";path=/";

              this.$router.push({ path: "/member/dashboard" });

            }
          })
          .catch((error) => {

          });
    },
    updateSchoolInfo(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$validator.validateAll('form-1').then((result) => {
        if (result) {
          this.updateMemberInfo(this.form1);
          this.$nextTick(() => {
            this.$bvModal.hide('modal-center1')
          });
        }

      });
    },
    updateAddressInfo(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.phone_1_error = "";
      if(this.form.phone_1==="") this.phone_1_error = "Phone Number is required";
      else {
        const validPhone1 = validatePhone(this.form.phone_1, this.validTelinput);
        this.phone_1_error = validPhone1.error;
      }
      this.$validator.validateAll('form-2').then((result) => {
        if (result) {
          if(this.phone_1_error !== "") return;
          this.updateMemberInfo(this.form);
          this.$nextTick(() => {
            this.$bvModal.hide('modal-center2')
          });
        }

      });
    },
    updateInfo(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$validator.validateAll('form-4').then((result) => {
          this.displayAlert = false;

        if (result) {
          this.updateMemberInfo(this.form4);
          this.$nextTick(() => {
            this.$bvModal.hide('modal-center4')
          });
        }

      });
    },
    updateMemberInfo(form){
      this.displayAlert = false;
      axios.post(this.basePath + 'api/update/campski/'+this.currentMemberId, form,{ headers:this.adminHeaders })
          .then(function (response) {
            this.userDatadetails = response.data.data;
            this.alertMessage = "Member Information updated successfully";
            this.displayAlert = true;
            this.userProfileApi();
            this.updateLoggedUserInfo += 1;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    addnote(){
      this.note = '';
      this.edit_note_id='';
      this.selectedNote='';
      this.notestatus=1;
    },
    displayAlertAndReloadData(response){
      this.alertMessage = response.message;
      this.displayAlert = true;
      this.userProfileApi();
      this.updateLoggedUserInfo += 1;
    },
    storenote(){

      $('.note-error, .note-type-error').html("");
      var error_flag = 0;
      if(this.note.trim() == ""){
        $('.note-error').html("Please enter note");
        error_flag++;
      }

      if(this.selectedNote == ""){
        $('.note-type-error').html("Please enter note type");
        error_flag++;
      }

      if(error_flag) return;

      let form = {'note': this.note,'member_id':this.userProfileData.id,'selectedNote':this.selectedNote,'notestatus':this.notestatus};

      this.noteProgress = true;
      axios.post(this.basePath+"api/Addnotes",form,{ headers:this.adminHeaders }).then(function (response){
            console.log(response)
            this.alertMessage = "Note added successfully";
            this.displayAlert = true;
            this.$bvModal.hide("note-modal");
            this.fetchnote();
            this.addnote();
            this.noteProgress = false;
            $('html, body').animate({
              scrollTop: $("#noteList").offset().top
            }, 1000);
          }.bind(this)
      )
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchnote(){
      this.regId = this.$route.params.regId;
      axios.get(this.basePath+"api/Addnotes/member/"+ this.regId,{ headers:this.adminHeaders }).then(response => {
        console.log(response);
        this.userNote = response.data;
        console.log("`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`");
        console.log(this.userNote);
        console.log("`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`*`");
        //this.notes = response.data.notes;

        console.log(response,"notes");
        //this.notestatus = response.data.notes.status;
      })
          .catch(err => {
            console.log(err);
          })
    },
    editNote(item, button) {

      console.log('hi');
      console.log(item.id);
      if(item.id){
        this.note=item.note;
        console.log( this.note);
        this.member_id = item.member_id;
        console.log( this.member_id);
        this.selectedNote = item.note_type;
        console.log(this.selectedNote);
        this.notestatus = item.status;
        console.log( this.notestatus);
        this.edit_note_id = item.id;
        console.log( this.edit_note_id);
        this.edit_index = item;
        console.log( this.edit_index);
        console.log( this.edit_index.id);
      }
    },
    updatenote(){
      $('.note-error, .note-type-error').html("");
      var error_flag = 0;
      if(this.note.trim() == ""){
        $('.note-error').html("Please enter note");
        error_flag++;
      }

      if(this.selectedNote == ""){
        $('.note-type-error').html("Please enter note type");
        error_flag++;
      }

      if(error_flag) return;

      this.noteProgress = true;
      let form = {'note': this.note,'member_id':this.userProfileData.id,'selectedNote':this.selectedNote,'notestatus':this.notestatus};
      axios.put(this.basePath+'api/Addnotes/'+this.edit_index.id,form,{ headers:this.adminHeaders }).then(function (response){
        this.alertMessage = "Note updated successfully";
        this.displayAlert = true;
        this.noteProgress = false;
        this.$bvModal.hide("note-modal");
        this.fetchnote();
      }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },

    deleteNote(id){
      this.displayAlert = false;
      this.$bvModal.msgBoxConfirm("Are you sure to delete this note ?" ,{
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        noCloseOnBackdrop: true,
        noCloseOnEsc: true,
        centered: true
      }).then(value => {

        console.log(value);
        if (String(value) == "true") {
          axios.delete(this.basePath+'api/Addnotes/'+id,{ headers:this.adminHeaders })
              .then(function (response) {
                console.log(response);
                this.alertMessage = "Note deleted Successfully";
                this.displayAlert = true;
                this.fetchnote();
              }.bind(this))
              .catch(function (error) {
                console.log(error);
              });
        }
      })
          .catch(err => {
            console.log(err);
          });
      return false;
    },
    loadPropsfieldsData(){
      const retry1 = setInterval(() => {
        this.Notetype = this.getSiteProps('Notetype.disciplines');
        console.log('test');
        console.log(this.Notetype);
        if(this.Notetype != "") clearInterval(retry1);
      }, 200);
    },
    getNoteTypeName(value){
      var key = 'Notetype.disciplines.options.'+value;
      var typName = this.getSiteProps(key);
      return typName || "N/A";
    },
    formatDatenew(value) {
      var date = moment(value.trim());
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    updateMemberDetails(bvModalEvt){
      bvModalEvt.preventDefault()
      if(this.validateUpdateMemberInfo()){
        this.updateMemberInfo();
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modalMembership')
        });
      }
    },
  },
  mounted() {
    this.userProfileApi();
    this.fetchnote();
    this.loadPropsfieldsData();
  },
  computed: {
   membershipStatusLists(){
     return this.membershipStatus.filter(item => item !=='');
   }
 },
  filters: {
    filterStateCode: function(value){
      if (!value) return '';
      return value.replace("state_code", "state");
    }
  }
}
</script>